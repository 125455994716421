@import '_var.scss';

.first-block {
    min-height: 1000px;
    padding: 280px 0 100px;
    background-size: cover;
    background-position: center;

    .left-block {
        max-width: 80%;
    }

    h3 {
        margin-top: 10px;
        font-family: $ProximaNovaLight;
    }

    .list {
        max-width: 90%;
        margin-top: 50px;
    }

    .btn {
        margin-top: 50px;
    }
}

.list {
    display: flex;

    &-item {
        flex: 1;
        padding-left: 90px;

        &:first-child {
            padding: 0;
        }
    }

    .icon {
        margin: 0 0 15px;
    }

    .title {
        font-family: $ProximaNovaBold;
        font-weight: bold;
        font-size: 23px;
    }

    p {
        margin-top: 10px;
        font-size: 21px;
    }
}


/*** Set block ***/

.set-block {
    display: flex;
    margin-top: 200px;

    .left-block {
        flex: 2;
        z-index: 5;
    }
    .right-block {
        flex: 1;
        padding-left: 100px;
        z-index: 4;
    }

    .title,
    .sub-title,
    .list {
        margin-bottom: 60px;
    }

    .btn {
        width: 100%;
        max-width: 400px;
    }

    .button-wrap {
        display: flex;
        align-items: flex-start;

        .law {
            margin: 0 -200px 0 140px;
        }
    }

    .law {
        display: flex;
        align-items: center;
        padding: 90px 80px 40px 0;
        background: $yellowLight;
        border-radius: 20px;
        box-shadow: 0 0 40px $shadowColorLight;

        .text {
            padding-left: 30px;
        }

        .title {
            margin: 0;
            font-size: 76px;
            font-family: $ProximaNovaLight;
        }
        p {
            margin: 0;
            font-size: 18px;
            font-family: $ProximaNovaLight;
            text-transform: uppercase;
        }
    }
}

.set-list {
    &-item {
        margin-top: 50px;
        padding: 30px 0 30px 50px;
        background: $white;
        border-radius: 20px;
        box-shadow: 0 0 40px $shadowColorLight;

        &:first-child {
            margin: 0;

            .image {
                padding-right: 0;
            }
        }

        .image {
            margin: 0 0 10px;
            padding-right: 30px;
            text-align: right;
        }

        .title {
            margin: 0;
            padding-right: 50px;
            font-size: 36px;
            line-height: 36px;
            font-family: $ProximaNovaSemibold;
        }
        p {
            margin: 15px 0 0;
            padding-right: 50px;
            font-size: 18px;
            line-height: 24px;
            font-family: $ProximaNovaLight;
            text-transform: uppercase;
        }
    }
}

/***/

.proprietary-software-wrap {
    margin: -80px 0 0;

    .title {
        max-width: 630px;
    }
}

.proprietary-software {
    display: flex;
    flex-wrap: wrap;
    padding-top: 100px;

    &-image {
        flex: 1;
        margin-right: -100px;
    }

    &-text {
        flex: 1;
    }

    .integration {
        max-width: 330px;
        margin: 100px 0 0 auto;

        p {
            margin-top: 40px;
            font-family: $ProximaNovaLight;
            font-size: 27px;
        }
    }

    .software {
        display: flex;
        margin-top: 40px;

        .image {
            margin-right: 20px;
        }
        .text {
            font-size: 18px;
        }
    }
}


/*** Condition ***/

.condition-wrap {
    margin-top: 160px;
    padding: 220px 0 130px;
    background-size: cover;
    background-position: center;
}
.condition-block {
    display: flex;
    align-items: center;

    .left-block,
    .right-block {
        flex: 1;
    }

    .left-block {
        padding-right: 60px;
    }

    .title {
        font-family: $ProximaNovaBold;

        span {
            font-size: 60px;
        }
    }

    p {
        margin-top: 60px;
        font-size: 31px;
        line-height: normal;
    }

    .alert-text {
        display: inline-block;
        margin-top: 50px;
        padding: 10px 20px;
        background: $otherYellow;
        font-size: 20px;
        font-family: $ProximaNovaSemibold;
        text-transform: uppercase;
        border-radius: 5px;
    }

    .info-text {
        margin-top: 50px;
        font-size: 21px;
        font-family: $ProximaNovaLight;
    }

    .date {
        display: flex;
        align-items: center;

        .day {
            margin-right: 70px;
            font-size: 220px;
            font-family: $ProximaNovaBoldItalic;
        }
        .month {
            padding: 0 20px 5px;
            background: $yellow;
            font-family: $ProximaNovaLightItalic;
            font-size: 65px;
            line-height: 56px;
            border-radius: 5px;
        }
        .year {
            margin-top: 15px;
            font-size: 76px;
            line-height: 76px;
            font-family: $ProximaNovaSemiboldItalic;
        }
    }
}


/*** fines ***/

.fines-block {
    margin-top: 190px;
    text-align: left;

    th, td {
        width: 25%;
        padding: 40px;
        vertical-align: middle;
    }

    th {
        vertical-align: top;
    }

    .td {
        border-top: 1px solid $borderColor;
    }

    img {
        display: block;
    }

    p {
        margin-top: 20px;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }

    .type {
        img {
            margin: 0 auto;
        }
        .label {
            margin: 20px 0 0;
            padding: 6px 10px;
            background: $yellowLight;
            color: $black;
            text-align: center;
            font-size: 12px;
            letter-spacing: 3px;
            text-transform: uppercase;
            border-radius: 5px;
        }
    }

    .list {
        p {
            font-family: $ProximaNovaSemibold;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    .label {
        margin: 0 0 40px;
        font-size: 20px;
        line-height: 20px;
        color: $darkGray;
    }

    .big-text {
        display: flex;
        font-size: 52px;
        line-height: 52px;
        font-family: $ProximaNovaBold;

        .item {
            padding: 0 0 0 20px;

            &:first-child {
                padding: 0;
            }
        }

        span {
            display: block;

            span {
                display: inline-block;
                font-size: 38px;
            }
        }

        .small {
            min-height: 12px;
            margin-bottom: 15px;
            font-size: 12px;
            line-height: 12px;
            text-transform: uppercase;
        }
    }
}


/*** Process ***/

.process-block-wrap {
    margin-top: 140px;
    padding: 270px 0 200px;
    background-repeat: no-repeat;

    .h1 {
        max-width: 960px;
    }
    .sub-title {
        margin-top: 70px;
        max-width: 960px;
    }

    .advantages {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 30px 0 0 -40px;

        &-item {
            margin: 40px 0 0 40px;
            padding: 17px 30px;
            background: $yellow;
            font-size: 16px;
            border-radius: 50px;
            text-transform: uppercase;
        }
    }

    .wide-image {
        width: 100%;
        margin: 140px auto 0;
    }
}

.process-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 130px;

    &-item {
        flex: 50%;
        margin: 80px 0 0;

        &:nth-child(even) {
            padding-left: 80px;

            .wrap {
                padding-left: 0;
            }
        }
    }

    .wrap {
        display: flex;
        padding-left: 100px;
    }

    .num {
        margin-right: 40px;
        font-family: $ProximaNovaBoldItalic;
        font-size: 66px;
        line-height: 54px;
        color: $green;
    }
    .title {
        font-family: $ProximaNovaSemibold;
        font-size: 36px;
    }
    p {
        font-size: 21px;
        line-height: 30px;
        font-family: $ProximaNovaLight;
        text-transform: uppercase;
    }

    .shadow {
        box-shadow: 0 0 40px $shadowColorLight;
    }

    .support {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 60px;
        background: $white;
        border-radius: 20px;
        box-shadow: 0 0 40px $shadowColorLight;

        .title {
            font-size: 41px;
            line-height: 41px;
            font-family: $ProximaNovaLight;

            img {
                max-width: 90px;
            }
        }

        a {
            display: flex;
            align-items: flex-end;
            margin: 50px 0 0;
            padding: 0 30px;
            background: $yellow;
            font-size: 20px;
            color: $black;
            text-transform: uppercase;
            border-radius: 50px;

            .icon {
                margin: -13px 15px 0 0;
            }

            .text {
                display: flex;
                align-items: center;
                height: 67px;
            }
        }
    }
}

/*** Functions ***/

.functions-wrap {
    .sub-title {
        margin-top: 100px;
    }

    .button-wrap {
        display: flex;
        justify-content: center;
        margin: 100px 0 0;
    }
}

.functions-list {
    display: flex;
    margin-top: 30px;

    &-item {
        flex: 1;
        padding-left: 90px;

        &:first-child {
            padding: 0;
        }
    }

    .image {
        margin-bottom: 40px;
        height: 110px;

        img {
            max-height: 100%;
        }
    }

    .title {
        margin-bottom: 40px;
        font-size: 30px;
        line-height: 30px;
        font-family: $ProximaNovaSemibold;
    }
    p {
        font-size: 16px;
        text-transform: uppercase;
    }
}


/*** Scheme ***/

.scheme-wrap {
    margin: 200px 0 0;

    img {
        width: 100%;
        margin: 100px 0 0;
    }
}


/*** Video ***/

.video-wrap {
    margin-top: 180px;

    .sub-title {
        margin-top: 40px;
    }

    .video {
        position: relative;
        width: 100%;
        max-width: 930px;
        height: 527px;
        margin: 100px auto 0;
        cursor: pointer;

        &.show {
            .hover {
                opacity: 0;
                pointer-events: none;
            }
            iframe {
                display: block;
            }
        }

        .hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../images/assets/video.jpg");
            background-size: cover;
            background-position: center;
            border-radius: 50px;
            transition: 0.3s;
            z-index: 99;
        }

        iframe {
            width: 100%;
            height: 100%;
            display: none;
        }
    }

    .button-wrap {
        display: flex;
        justify-content: center;
        margin: 100px 0 0;
    }
}


/*** Price ***/

.price-wrap {
    margin-top: 160px;

    .h1, .small-title {
        text-align: center;
    }
    .small-title {
        margin-top: 50px;
        font-family: $ProximaNovaSemibold;
        font-size: 21px;
        text-transform: uppercase;
    }

    .price-block {
        display: flex;
        margin-top: 90px;

        .left-block,
        .right-block {
            flex: 1;
        }

        .image {
            display: flex;
            justify-content: flex-end;
        }

        .left-block {
            padding-right: 15px;

            .head {
                padding: 50px 0 0 50px;
            }

            .price-list {
                padding: 0 50px 50px;

                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 30px 0;
                    border-top: 1px solid $borderYellowColor;

                    &:first-child {
                        border: 0;
                    }
                }

                .label {
                    font-family: $ProximaNovaLight;
                    font-size: 20px;
                    text-transform: uppercase;
                }
                .cost {
                    font-size: 27px;
                    font-family: $ProximaNovaSemibold;
                }
            }
        }
        .right-block {
            padding-left: 15px;

            .head {
                display: flex;
                margin-bottom: 30px;
                padding: 60px 0 60px 40px;
                background: $white;
                border-radius: 20px;
                box-shadow: 0 0 40px $shadowColorLight;
            }

            .price-list {
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 30px 0;
                }

                .label {
                    font-family: $ProximaNovaSemibold;
                    font-size: 20px;
                    text-transform: uppercase;
                }
                .cost {
                    font-size: 27px;
                    font-family: $ProximaNovaSemibold;
                }

                .small {
                    font-size: 16px;
                }
            }
        }

        .head {
            display: flex;
            overflow: hidden;

            img {
                position: relative;
                left: 20px;
            }

            .title {
                font-size: 41px;
                font-family: $ProximaNovaLight;

                img {
                    max-width: 90px;
                }
            }
        }
        .yellow-block {
            background: $otherYellow;
            border-radius: 10px;
        }
    }
}


/*** Get started ***/

.get-started-wrap {
    margin-top: 130px;

    .h1 {
        text-align: center;
    }
}

.get-started-block {
    display: flex;
    justify-content: space-between;
    margin-top: 130px;

    .item {
        margin-left: 100px;

        &:first-child {
            margin: 0;
        }
    }

    .phone {
        display: flex;
        align-items: center;
        font-size: 34px;
        color: $black;

        .icon {
            margin-right: 15px;

            img {
                width: 18px;
            }
        }

        &.yellow {
            padding: 5px 20px;
            background: $otherYellow;
            border-radius: 10px;
        }
    }

    .mail {
        .icon {
            margin-right: 15px;

            img {
                width: 27px;
            }
        }

        .text {
            font-size: 37px;
            font-family: $ProximaNovaLight;
            border-bottom: 1px solid;
        }
    }

    .small {
        padding: 20px 0 0 50px;
        font-size: 14px;
        color: $darkGray;
    }

    .messengers {
        display: flex;
        margin: 20px 0 0;

        &-item {
            display: flex;
            align-items: center;
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            .icon {
                min-width: 22px;
                margin-right: 10px;
            }

            .label {
                font-size: 12px;
                color: $darkGray;
            }
        }
    }
}


/*** Contact ***/

.contact-block {
    position: relative;
    margin-top: 130px;
    z-index: 5;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 40px;
        left: 50%;
        width: calc(100% - 45px);
        height: 100%;
        background: $white;
        transform: translateX(-50%);
        border-radius: 10px;
        box-shadow: 0 0 15px $shadowColorLight;
        z-index: 8;
    }
    &:after {
        width: calc(100% - 25px);
        top: 20px;
        z-index: 9;
    }

    .wrap {
        position: relative;
        padding: 90px 120px;
        background: $white;
        border-radius: 10px;
        box-shadow: 0 0 15px $shadowColorLight;
        z-index: 99;
    }

    .person-wrap {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 9;

        .image {
            margin-right: 50px;
        }
        .label {
            color: $darkGray;
            font-size: 20px;
            font-family: $ProximaNovaSemibold;
            text-transform: uppercase;
            letter-spacing: 6px;
        }
        .name {
            margin-top: 15px;
            font-size: 32px;
            text-transform: uppercase;
        }
    }

    .form {
        position: relative;
        margin-top: 50px;
        z-index: 9;
    }
}